@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "backgrounds";

@import "fonts";

@import "layout";

@import "misc-utils";

@import "sizes";

@import "svgs";

.nukia-pink {
  color: #f7a3a3;
}

.nukia-blue {
  color: #3d70e0;
}
