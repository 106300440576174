.bg-nukia-logo {
  width: width("../mdhuli.svg", 3);
  height: height("../mdhuli.svg", 3);
  background-size: size("../mdhuli.svg", 3);
  background-image: svg-load("../mdhuli.svg", fill= "#1a202c");
}

.bg-pattern-1 {
  background-image: -webkit-linear-gradient(
    0deg,
    hsl(9, 100%, 96%) 50%,
    hsl(243, 100%, 69%) 0
  );
}

.bg-nukia-orange {
  background-color: hsl(9, 100%, 71%);
}

.bg-nukia-orange-light {
  background-color: hsl(9, 100%, 96%) 50%;
}

.bg-texture-orange {
  background-image: svg-load("../texture.svg", fill=hsl(9, 100%, 71%));
}

.bg-texture-pink {
  background-image: svg-load(
    "../texture.svg",
    fill=hsla(305, 100%, 50%, 0.596)
  );
}

.bg-texture-purple {
  background-image: svg-load("../texture.svg", fill=hsl(243, 100%, 57%));
}

.bg-logo-alex-mcqueen {
  width: 17em;
  background-image: svg-load(
    "../logo/alex-mcqueen.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-bottege-veneta {
  width: 21.7em;
  background-image: svg-load(
    "../logo/bottege-veneta.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-burberry {
  width: 11.2em;
  background-image: svg-load(
    "../logo/burberry.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-jimmy-choo {
  width: 10.8em;
  background-image: svg-load(
    "../logo/jimmy-choo.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-balenciaga {
  background-image: svg-load(
    "../logo/balenciaga.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-brioni {
  width: 4em;
  background-image: svg-load(
    "../logo/brioni.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-davidoff {
  width: 12.9em;
  background-image: svg-load(
    "../logo/davidoff.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-guerlain {
  width: 12.4em;
  background-image: svg-load(
    "../logo/guerlain.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-logo-saint-laurent {
  width: 13.9em;
  background-image: svg-load(
    "../logo/saint-laurent.svg",
    fill=hsla(0, 0%, 27%, 0.822)
  );
}

.bg-tint {
  background-blend-mode: multiply;
}
