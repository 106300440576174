.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-section {
  height: 52rem;
}

/* Small (sm) */
@media (min-width: 640px) {
  .h-section {
    height: auto;
  }
}

/* Medium (md) */
@media (min-width: 768px) {
  .h-section {
    height: auto;
  }
}

/* Large (lg) */
@media (min-width: 1024px) {
  .h-section {
    height: 52rem;
  }
}

/* Extra Large (xl) */
@media (min-width: 1280px) {
  .h-section {
    height: 52rem;
  }
}

.w-max-content {
  width: max-content;
}

