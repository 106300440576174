.flex-basis-full {
  flex-basis: 100%;
}

.floating-header-svg {
  top: auto;
  left: auto;
  width: auto;
  margin-top: auto;
}

/* Small (sm) */
@media (min-width: 640px) {
  .floating-header-svg {
    top: 45px;
    left: 10%;
    width: 26rem;
    margin-top: 15rem;
  }
}

/* Medium (md) */
@media (min-width: 768px) {
  .floating-header-svg {
    top: 0;
    left: auto;
    width: 100%;
    margin-top: auto;
  }
}

/* Large (lg) */
@media (min-width: 1024px) {
  .floating-header-svg {
    top: 115px;
    left: auto;
    width: 100%;
    margin-top: auto;
  }
}

/* Extra Large (xl) */
@media (min-width: 1280px) {
  .floating-header-svg {
    top: 0;
    left: auto;
    width: 100%;
    margin-top: auto;
  }
}

/* Extra Large (2xl) */
@media (min-width: 1440px) {
  .floating-header-svg {
    top: -115px;
    left: auto;
    width: 100%;
    margin-top: auto;
  }
}
